import jQuery from "jquery";
window.$ = jQuery; // workaround for https://github.com/parcel-bundler/parcel/issues/333
import "bootstrap";
import instantsearch from "instantsearch.js/es";
import {
  geoSearch,
  searchBox,
  infiniteHits,
  refinementList,
  rangeSlider,
  stats,
  configure,
  analytics,
} from "instantsearch.js/es/widgets";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { SearchClient as TypesenseSearchClient } from "typesense"; // To get the total number of docs

let TYPESENSE_SERVER_CONFIG = {
  apiKey: process.env.TYPESENSE_SEARCH_ONLY_API_KEY,
  nodes: [
    {
      host: process.env.TYPESENSE_HOST,
      port: process.env.TYPESENSE_PORT,
      protocol: process.env.TYPESENSE_PROTOCOL,
    },
  ],
  numRetries: 8,
};

const INDEX_NAME = process.env.TYPESENSE_COLLECTION_NAME;

async function getIndexSize() {
  let typesenseSearchClient = new TypesenseSearchClient(
    TYPESENSE_SERVER_CONFIG,
  );
  let results = await typesenseSearchClient
    .collections(INDEX_NAME)
    .documents()
    .search({ q: "*", group_by: "songidx", group_limit: 1 }); //gs: consolidate items and remove duplicates in the search results: group_by: "songidx", group_limit: 1
  return results["found"];
}

let indexSize;
let chartData;

(async () => {
  indexSize = await getIndexSize();
})();

// Initialize the leaflet map
let map = L.map("map").setView([46.05108, 14.50513], 8);
// Add Tile Layer
L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  attribution: "© OpenStreetMap contributors",
}).addTo(map);

let locationArray = [];
let search;

// MARK: ===== CHART ======
const yearStart = 1650;
const yearEnd = 2000;
const yearStep = 10;
const typeYears = [];
for (let year = yearStart; year <= yearEnd; year += yearStep) {
  typeYears.push(year);
}

// Create a canvas element where the chart will be displayed
const ctx = document.getElementById("myChart").getContext("2d");

// const Utils = {
//   CHART_COLORS: {
//     // Function to generate a unique color
//     nextColor: function () {
//       // Generate a random color code
//       return "#" + Math.floor(Math.random() * 16777215).toString(16);
//     },
//   },
// };

// // Initialize an object to store colors for each type
// let typeColors = {};

const colorMap = {}; // Store generated colors based on type

// Function to generate a pastel color based on type
function getColorForType(type) {
  if (!colorMap[type]) {
    // Generate random pastel color
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    colorMap[type] = color;
  }
  return colorMap[type];
}

// async function getYearsAndCounts(searchQuery) {
//   let typesenseSearchClient = new TypesenseSearchClient(
//     TYPESENSE_SERVER_CONFIG,
//   );
//   let results = await typesenseSearchClient
//     .collections(INDEX_NAME)
//     .documents()
//     .search({
//       q: searchQuery,
//       group_by: "type", // Group by type
//       query_by:
//         "text, year_str, region, places, book_short_title, has_recording",
//       group_limit: 99,
//       per_page: 250,
//     });
//   // console.log("FIRST SEARCH");
//   // console.log(results);

//   // if (!chartData) {
//   //   console.log("chartData is empty");
//   //   chartData = results.grouped_hits;
//   // }

//   // Initialize an object to store data
//   let data = [];

//   // Loop through the grouped hits
//   results.grouped_hits.forEach((hit) => {
//     // Initialize an object to store counts for each year
//     let yearCounts = {};

//     // Loop through the hits of each group
//     hit.hits.forEach((obj) => {
//       // Extract the year from each object
//       const year = obj.document.year;

//       // Increment the count for the year
//       if (!yearCounts[year]) {
//         yearCounts[year] = 1;
//       } else {
//         yearCounts[year]++;
//       }
//     });

//     // Initialize an array to store the counts in order
//     let countData = [];

//     // Loop from 1770 to 2000 and push the counts into the array
//     for (let year = yearStart; year <= yearEnd; year += yearStep) {
//       // If the year exists in yearCounts, push its count, otherwise push 0
//       if (year === 1770) {
//         countData.push(0);
//       } else {
//         countData.push(yearCounts[year] || 0);
//       }
//     }

//     // for every 10 years join countData and set them in new joinedCountsArray
//     // Initialize an array to store the joined counts
//     let joinedCountsArray = [];

//     // Loop from 1650 to 2000 in steps of 10
//     for (let year = yearStart; year <= yearEnd; year += yearStep) {
//       // Initialize a sum for this 10-year period
//       let sum = 0;

//       // Add the counts for each year in this 10-year period
//       for (let offset = 0; offset < 10; offset++) {
//         sum += yearCounts[year + offset] || 0;
//       }

//       // Push the sum to the joinedCountsArray
//       joinedCountsArray.push(sum);
//     }

//     // Replace countData with joinedCountsArray
//     countData = joinedCountsArray;

//     // Generate a unique background color for this type if not already generated
//     const type = hit.group_key[0];
//     const backgroundColor = typeColors[type] || Utils.CHART_COLORS.nextColor();
//     typeColors[type] = backgroundColor; // Store the generated color for future use

//     // Create the data object and push it to the data array
//     data.push({
//       label: type,
//       data: countData,
//       backgroundColor: backgroundColor,
//       barPercentage: 1,
//     });
//   });

//   return data;
// }

async function getChartData(searchResults) {
  // Initialize an object to store data
  let data = [];

  // Loop through the grouped hits
  results.grouped_hits.forEach((hit) => {
    // Initialize an object to store counts for each year
    let yearCounts = {};

    // Loop through the hits of each group
    hit.hits.forEach((obj) => {
      // Extract the year from each object
      const year = obj.document.year;

      // Increment the count for the year
      if (!yearCounts[year]) {
        yearCounts[year] = 1;
      } else {
        yearCounts[year]++;
      }
    });

    // Initialize an array to store the counts in order
    let countData = [];

    // Loop from 1770 to 2000 and push the counts into the array
    for (let year = yearStart; year <= yearEnd; year += yearStep) {
      // If the year exists in yearCounts, push its count, otherwise push 0
      if (year === 1770) {
        countData.push(0);
      } else {
        countData.push(yearCounts[year] || 0);
      }
    }

    // for every 10 years join countData and set them in new joinedCountsArray
    // Initialize an array to store the joined counts
    let joinedCountsArray = [];

    // Loop from 1650 to 2000 in steps of 10
    for (let year = yearStart; year <= yearEnd; year += yearStep) {
      // Initialize a sum for this 10-year period
      let sum = 0;

      // Add the counts for each year in this 10-year period
      for (let offset = 0; offset < 10; offset++) {
        sum += yearCounts[year + offset] || 0;
      }

      // Push the sum to the joinedCountsArray
      joinedCountsArray.push(sum);
    }

    // Replace countData with joinedCountsArray
    countData = joinedCountsArray;

    // Generate a unique background color for this type if not already generated
    const type = hit.group_key[0];
    const backgroundColor = typeColors[type] || Utils.CHART_COLORS.nextColor();
    typeColors[type] = backgroundColor; // Store the generated color for future use

    // Create the data object and push it to the data array
    data.push({
      label: type,
      data: countData,
      backgroundColor: backgroundColor,
      barPercentage: 1,
    });
  });

  return data;
}

// (async () => {
// let searchQuery = "*";

// let data = await getChartData(searchQuery)
// * DATA should be an array of obyects like so:
// {
//  backgroundColor: "#afbd01",
//  barPercentage: 1,
//  data:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 5, 1, 1, 0, 2, 1, 3, 19, 4, 0, 3, 0, 17, 11, 7, 5, 0, 0,...],
//  label: "287. OBSOJENA DETOMORILKA",
// }

let data = [];
// console.log("MY DATA FOR CHART");
// console.log(data);

// MARK: Create the chart
const myChart = new Chart(ctx, {
  type: "bar", // Change the chart type as needed (e.g., 'bar', 'line', etc.)
  data: {
    labels: typeYears,
    datasets: data,
  },
  options: {
    plugins: {
      title: {
        display: false,
        text: "Pojavitev variant skozi leta",
        font: {
          family: "Palanquin",
          size: 16,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        filter: function (tooltipItem, data) {
          return tooltipItem.formattedValue !== "0";
        },
        titleFont: {
          family: "Palanquin",
        },
        bodyFont: {
          family: "Palanquin",
        },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        stacked: true,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    elements: {
      bar: {
        barThickness: 1, // Set bar thickness to remove padding
      },
    },
    normalized: true,
  },
});
// })();

// MARK: RENDER SEARCH

function renderSearch(searchType) {
  if (search) {
    search.dispose();
  }

  let queryBy;
  queryBy = "text, year_str, region, places, book_short_title, has_recording"; //, tags
  //
  // if (searchType === "keyword") {
  //   queryBy = "text, year_str, region, place, book_short_title, tags";
  // } else if (searchType === "semantic") {
  //   queryBy = "embedding";
  // } else {
  //   queryBy = "text,embedding";
  // }

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: TYPESENSE_SERVER_CONFIG,
    geoLocationField: "location",
    additionalSearchParameters: {
      // parameters: https://github.com/typesense/typesense-instantsearch-semantic-search-demo/blob/master/src/app.js
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  queryBy is required.
      query_by: queryBy,
      exclude_fields: "embedding",
      // Deduplication: By using one or more group_by fields, you can consolidate items and remove duplicates in the search results.
      // https://typesense.org/docs/0.20.0/api/documents.html#search
      group_by: "songidx", //this looses results count for semantic search
      group_limit: 1,
      sort_by: "ids:asc",
    },
  });
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  search = instantsearch({
    searchClient,
    indexName: INDEX_NAME,
    routing: true,
    // async searchFunction(helper) {
    //   // This fetches 200 (nearest neighbor) results for semantic / hybrid search
    //
    //   let query = helper.getQuery().query;
    //   const page = helper.getPage(); // Retrieve the current page
    //
    //   if (
    //     query !== "" &&
    //     ["semantic", "hybrid"].includes($("#search-type-select").val())
    //   ) {
    //     console.log(helper.getQuery().query);
    //     helper
    //       .setQueryParameter(
    //         "typesenseVectorQuery", // <=== Special parameter that only works in typesense-instantsearch-adapter@2.7.0-3 and above
    //         `embedding:([], k:200)`,
    //       )
    //       .setPage(page)
    //       .search();
    //     console.log(helper.getQuery().query);
    //   } else {
    //     helper
    //       .setQueryParameter("typesenseVectorQuery", null)
    //       .setPage(page)
    //       .search();
    //   }
    // },
  });

  search.addWidgets([
    searchBox({
      container: "#searchbox",
      showSubmit: false,
      showReset: false,
      placeholder: "Vpiši iskalno zahtevo ali izberi tip pesmi",
      autofocus: true,
      cssClasses: {
        input: "form-control",
      },
    }),
    // geoSearch({
    //   container: '#map',
    //   googleReference: window.google,
    //   enableClearMapRefinement: false,
    //   enableRefineControl: false,
    //   builtInMarker: {
    //     createOptions(item) {
    //       return {
    //         title: item.year_str,
    //       };
    //     },
    //   },
    // }),

    stats({
      container: "#stats",
      cssClasses: {
        text: "text-muted small",
        root: "fw-bold pb-2",
      },
      templates: {
        text: ({ nbHits, hasNoResults, hasOneResult, processingTimeMS }) => {
          let statsText = "";
          if (hasNoResults) {
            statsText = "Ni rezultatov";
          } else if (hasOneResult) {
            statsText = "1 rezultat";
          } else {
            statsText = `${nbHits.toLocaleString()} variant`;
          }
          return `${statsText} od ${indexSize}`;
        },
      },
    }),
    infiniteHits({
      container: "#hits",
      cssClasses: {
        // list: "list-unstyled grid-container",
        list: "list-unstyled row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4",
        item: "col",
        loadMore: "btn btn-primary mx-auto d-block mt-4",
      },
      templates: {
        item(hit) {
          let text = hit._highlightResult.text.value || hit.value;
          let myMeta = hit.meta;
          // text = text
          //   .replace(/\n/g, "\\n")
          //   .replace(/\r/g, "\\r")
          //   .replace(/\t/g, "\\t");
          // text = JSON.parse(`"${text}"`);
          // console.log(text);
          text = text
            .replace(/\n/g, "<br>")
            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
          // Truncate the text to 75 characters
          // let truncatedMeta =
          //   myMeta.length > 75 ? myMeta.substring(0, 75) + "..." : myMeta;

          let customHit = {
            hitTitle: hit.type,
            hitId: hit.id,
            hitLocation: hit.location,
            hitVar_nr: hit.var_nr,
            hitYear: hit.year_str,
            hitRegion: hit.region,
            hitPlace: hit.place,
            // hitTags: hit.tags,
            hitBook_short_title: hit.book_short_title,
          };

          locationArray.push(customHit);
          let fullAudio =
            '<p class="w-100 small">Avdio posnetek žal ni na voljo</p>';
          let teaserAudio = "";
          if (hit.mp3_path.length > 0) {
            fullAudio = `<audio controls src="${hit.mp3_path}" class="w-100"></audio>`;
            teaserAudio = `
            <button class="card-btn-play btn btn-light rounded-0 position-absolute bottom-0 end-0">
              <i class="fa-solid fa-play"></i>
            </button>
            <audio controls class="card-audio visually-hidden" src="${hit.mp3_path}"></audio>`;
          }
          return `
            <article class="col">
              <div class="result-container mb-4 card overflow-hidden rounded-3 border-0 shadow-sm position-relative">
              <div class="card-header">
                <span class="text-primary card-title pr-2">${hit.type}</span>
                <a class="btn btn-light show-more-btn text-primary rounded-0 position-absolute top-0 end-0" data-bs-toggle="modal" href="#${
                  hit.id
                }" role="button">
                  <i class="fa-solid fa-up-right-and-down-left-from-center text-muted"></i>
                </a>
                <div class="text-muted small pr-2">
                  <span class="text-secondary">${hit.var_nr}</span> | 
                  <span class="text-decoration-none">${hit.year_str} | 
                  <span class="text-decoration-none">${hit.region}</span> |
                  <span class="text-decoration-none">${hit.place}</span> |
<!--                  <span class="text-decoration-none">${hit.tags}</span> |-->
                  <span class="text-decoration-none">${hit.book_short_title}
                </div>
              </div>
              <div class="card-body overflow-auto" style="height:300px">
                <div class="mt-2 full-text card-text w-100">
                <p>${decodeHtml(text)}</p>
                </div>
              </div>
              <div class="mt-2 card-footer">
                ${teaserAudio}
                <span class="text-muted small">
                  ${myMeta}
                </span>
              </div>
            </div>
            <div class="modal fade" id="${
              hit.id
            }" aria-hidden="true" aria-labelledby="${
              hit.id
            }Label" tabindex="-1">
              <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="d-flex flex-column">
                      <h4 class="modal-title" id="${hit.id}Label">${
                        hit.type
                      }</h4>
                      <div class="text-muted small">
                        <span class="text-secondary">${hit.var_nr}</span> | 
                        <span class="text-decoration-none">${hit.year} | 
                        <span class="text-decoration-none">${
                          hit.region
                        }</span> |
                        <span class="text-decoration-none">${hit.place}</span> |
                        <span class="text-decoration-none">${hit.tags}</span> |
                        <span class="text-decoration-none">${
                          hit.book_short_title
                        }
                      </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p>${decodeHtml(text)}</p>
                    <h5 class="mt-4">Metapodatki:</h5>
                    <span class="">
                    ${hit.meta}
                    </span>
                  </div>
                  <div class="modal-footer">
                    ${fullAudio}
                  </div>
                </div>
              </div>
            </article>
          `;
        },
        showMoreText(data, { html }) {
          return html`<span>Prikaži več zadetkov</span>`;
        },
        empty:
          "Ni nobenih zadetkov za <q>{{ query }}</q>. Poskusi z novo poizvedbo.",
      },
      // transformItems: (items) => {
      //   return items.map((item) => {
      //     return {
      //       ...item,
      //       display_timestamp: (() => {
      //         const parsedDate = new Date(item.year * 1000);
      //         return `${parsedDate.toLocaleString()}`;
      //       // display_timestamp: (() => {
      //       //   const parsedDate = new Date(item.year); //gs
      //       //   return `${parsedDate.getFullYear()}`;
      //       })(),
      //     };
      //   });
      // },
    }),
    refinementList({
      container: "#types-refinement-list",
      attribute: "type",
      searchable: true,
      searchablePlaceholder: "Iskanje po tipih pesmi",
      showMore: true,
      showMoreLimit: 100000,
      cssClasses: {
        searchableInput: "form-control form-control-sm mb-2 border-light-2",
        searchableSubmit: "d-none",
        searchableReset: "d-none",
        showMore: "btn btn-primary btn-sm",
        list: "list-unstyled overflow-y-auto overflow-x-hidden",
        count: "badge rounded-pill bg-dark fw-normal ms-auto",
        label: "d-flex align-items-center",
        checkbox: "me-2",
        labelText: "pe-1",
        item: "px-2",
      },
      templates: {
        showMoreText(data, { html }) {
          return html`<span
            >${data.isShowingMore ? "Prikaži manj" : "Prikaži več"}</span
          >`;
        },
      },
    }),
    rangeSlider({
      container: "#year-slider",
      attribute: "year",
      min: 1650,
      max: 2000,
      step: 10,
    }),
    configure({
      hitsPerPage: 100,
    }),
  ]);

  search.on("render", function () {
    // Clear all previously displayed markers from the map
    map.eachLayer(function (layer) {
      if (layer instanceof L.Marker) {
        map.removeLayer(layer);
      }
    });

    // Add new markers to the map from locationArray
    locationArray.forEach(function (customHit) {
      let marker = L.marker(customHit.hitLocation);
      marker.addTo(map);
      marker.bindPopup(`
      <a class="show-more-btn d-flex flex-column" data-bs-toggle="modal" href="#${customHit.hitId}" role="button">
      <h5 class="h5">${customHit.hitTitle}</h5>
      <div class="text-muted small pr-2">
        <span class="text-secondary">${customHit.hitVar_nr}</span> | 
        <span class="text-decoration-none">${customHit.hitYear} | 
        <span class="text-decoration-none">${customHit.hitRegion}</span> |
        <span class="text-decoration-none">${customHit.hitPlace}</span> |
<!--        <span class="text-decoration-none">${customHit.hitTags}</span> |-->
        <span class="text-decoration-none">${customHit.hitBook_short_title}
      </div>
    </a>`);
    });

    locationArray = [];

    // Make type names clickable
    $("#hits .clickable-search-term").on("click", handleSearchTermClick);

    // Attach click event handler to button with class card-btn-play
    // on click play audio player and change the button icon to pause
    // if clicked again change the button icon to play and pause the button
    $(".card-btn-play").on("click", function () {
      const $audio = $(this).siblings("audio");
      if ($audio[0].paused) {
        $audio[0].play();
        $(this).html(`<i class="fa-solid fa-pause"></i>`);
      } else {
        $audio[0].pause();
        $(this).html(`<i class="fa-solid fa-play"></i>`);
      }
    });

    // MARK: === get data for the chart ===
    // console.log("================ ON SEARCH =================");
    // console.log(search.helper);
    // console.log(search.helper.lastResults.hits);
    // chartData = search.helper.lastResults.hits;

    let myNewData = [];
    const fakeYear = 1770;
    let hits = search.helper.lastResults.hits;
    const groupedHits = hits.reduce((acc, hit) => {
      if (!acc[hit.type]) {
        acc[hit.type] = [];
      }
      acc[hit.type].push(hit);
      return acc;
    }, {});

    // console.log(groupedHits);

    const years = {};

    for (let year = 1650; year <= 2000; year++) {
      years[year] = 0;
    }

    // console.log(years);

    for (const type in groupedHits) {
      // Reset values to 0 for each type
      for (const year in years) {
        years[year] = 0;
      }

      // Update values based on hits
      for (const hit of groupedHits[type]) {
        years[hit.year]++;
      }

      // Create object for myNewData
      const newDataItem = {
        label: type,
        data: [],
        barPercentage: 1,
        backgroundColor: getColorForType(type),
      };

      // Populate data array in ascending order of years
      // for (let year = 1650; year <= 2000; year++) {
      //   newDataItem.data.push(years[year]);
      // }

      // Populate data array with sum of every 10 consecutive values
      let sum = 0;
      let count = 0;
      for (let year = 1650; year <= 2000; year++) {
        if (year !== fakeYear) {
          sum += years[year];
        }
        count++;
        if (count === 10) {
          newDataItem.data.push(sum);
          sum = 0;
          count = 0;
        }
      }

      // Push remaining sum if there are less than 10 values left
      if (count > 0) {
        newDataItem.data.push(sum);
      }

      // Push newDataItem to myNewData array
      myNewData.push(newDataItem);
    }

    // console.log(myNewData);
    // console.log(myChart.data);

    // Update chart data with myNewData
    myChart.data.datasets = myNewData;

    // Update chart
    myChart.update();

    // chartData = search.helper.lastResults.hits;
    // chart.update();
  });

  /*  const yearStart = 1650;
  const yearEnd = 2000;
  const yearStep = 10;
  const typeYears = [];
  for (let year = yearStart; year <= yearEnd; year += yearStep) {
    typeYears.push(year);
  }
  // Create a canvas element where the chart will be displayed
  const ctx = document.getElementById("myChart").getContext("2d");

  const Utils = {
    CHART_COLORS: {
      // Function to generate a unique color
      nextColor: function () {
        // Generate a random color code
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
      },
    },
  };

  // Initialize an object to store colors for each type
  let typeColors = {};

  async function getChartData() {
    let data = [];

    // Loop through the grouped hits
    chartData.forEach((hit) => {
      // Initialize an object to store counts for each year
      let yearCounts = {};

      // Loop through the hits of each group
      hit.hits.forEach((obj) => {
        // Extract the year from each object
        const year = obj.document.year;

        // Increment the count for the year
        if (!yearCounts[year]) {
          yearCounts[year] = 1;
        } else {
          yearCounts[year]++;
        }
      });

      // Initialize an array to store the counts in order
      let countData = [];

      // Loop from 1770 to 2000 and push the counts into the array
      for (let year = yearStart; year <= yearEnd; year += yearStep) {
        // If the year exists in yearCounts, push its count, otherwise push 0
        if (year === 1770) {
          countData.push(0);
        } else {
          countData.push(yearCounts[year] || 0);
        }
      }

      // for every 10 years join countData and set them in new joinedCountsArray
      // Initialize an array to store the joined counts
      let joinedCountsArray = [];

      // Loop from 1650 to 2000 in steps of 10
      for (let year = yearStart; year <= yearEnd; year += yearStep) {
        // Initialize a sum for this 10-year period
        let sum = 0;

        // Add the counts for each year in this 10-year period
        for (let offset = 0; offset < 10; offset++) {
          sum += yearCounts[year + offset] || 0;
        }

        // Push the sum to the joinedCountsArray
        joinedCountsArray.push(sum);
      }

      // Replace countData with joinedCountsArray
      countData = joinedCountsArray;

      // Generate a unique background color for this type if not already generated
      const type = hit.type;
      const backgroundColor = typeColors[type] || Utils.CHART_COLORS.nextColor();
      typeColors[type] = backgroundColor; // Store the generated color for future use

      // Create the data object and push it to the data array
      data.push({
        label: type,
        data: countData,
        backgroundColor: backgroundColor,
        barPercentage: 1,
      });
    });

    return data;
  }

  let data = getChartData();

  // Create the chart
  const myChart = new Chart(ctx, {
    type: "bar", // Change the chart type as needed (e.g., 'bar', 'line', etc.)
    data: {
      labels: typeYears,
      datasets: data,
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: "Pojavitev variant skozi leta",
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
          stacked: true,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      elements: {
        bar: {
          barThickness: 1, // Set bar thickness to remove padding
        },
      },
      normalized: true,
    },
  }); */

  search.start();
}

function handleSearchTermClick(event) {
  const $searchBox = $("#searchbox input[type=search]");
  search.helper.clearRefinements();
  $searchBox.val(event.currentTarget.textContent);
  search.helper.setQuery($searchBox.val()).search();
}

// Source: https://stackoverflow.com/a/42182294/123545
function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

$(function () {
  const $searchBox = $("#searchbox input[type=search]");

  renderSearch($("#search-type-select").val());

  // Handle example search terms
  $(".clickable-search-term").on("click", handleSearchTermClick);
  $("#search-type-select").on("change", function () {
    const searchType = this.value;
    renderSearch(searchType);
  });

  // Clear refinements, when searching
  $searchBox.on("keydown", (event) => {
    search.helper.clearRefinements();
  });

  if (!matchMedia("(min-width: 768px)").matches) {
    $searchBox.on("focus, keydown", () => {
      $("html, body").animate(
        {
          scrollTop: $("#searchbox-container").offset().top,
        },
        500,
      );
    });
  }
  // ! MARK: CHANGES FOR SCROLL SEARCHBAR
  // on scroll check if element with a class search-form has a sticky display and the element is positioned 60px from the top of the page, then add a class stick or remove it if it does not have it
  // $(window).on("scroll", function () {
  //   if ($(".search-form").css("position") === "sticky") {
  //     let scrollTop = $(window).scrollTop();
  //     let elementOffset = $(".search-form").offset().top;
  //     let distance = elementOffset - scrollTop;
  //     if (distance <= 56) {
  //       $(".search-form").addClass("stuck full-bleed");
  //     } else {
  //       $(".search-form").removeClass("stuck full-bleed");
  //     }
  //   }
  // });

  document.addEventListener("DOMContentLoaded", () => {
    const tooltipTarget = document.getElementById("custom-tooltip-target");
    const tooltipContent = document.getElementById("custom-tooltip-content");

    tooltipTarget.addEventListener("mouseover", () => {
      tooltipContent.style.visibility = "visible";
      tooltipContent.style.opacity = "1";
    });

    tooltipTarget.addEventListener("mouseout", (event) => {
      if (!tooltipContent.contains(event.relatedTarget)) {
        tooltipContent.style.visibility = "hidden";
        tooltipContent.style.opacity = "0";
      }
    });

    tooltipContent.addEventListener("mouseover", () => {
      tooltipContent.style.visibility = "visible";
      tooltipContent.style.opacity = "1";
    });

    tooltipContent.addEventListener("mouseout", (event) => {
      if (!tooltipTarget.contains(event.relatedTarget)) {
        tooltipContent.style.visibility = "hidden";
        tooltipContent.style.opacity = "0";
      }
    });
  });
});
